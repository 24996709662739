import React, { useEffect, useState } from "react";

import Selecao from "./componentes/Selecao/Selecao";
import Pergunta from "./componentes/Pergunta/Pergunta";
// import mainJson from "./perguntas.json";
import SnackbarProvider from "react-simple-snackbar";

const HomeScreen = () => {
  const [allQuestions, setAllQuestions] = useState();
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [perguntaSelecionada, setPerguntaSelecionada] = useState({});

  useEffect(() => {
    fetch("https://pcs-perguntas.programadorcs.com.br/perguntas.json", {
      mode: "no-cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setAllQuestions(data);
      });
    // setAllQuestions(mainJson);
  }, []);

  useEffect(() => {}, [allQuestions]);

  const handleselectNewCategory = (item) => {
    setCategoriaSelecionada(item);
    setPerguntaSelecionada({});
  };
  const handleGenerateQuestions = () => {
    if (categoriaSelecionada in allQuestions?.perguntas) {
      const perguntas = allQuestions?.perguntas[categoriaSelecionada];
      const pergunta = perguntas[Math.floor(Math.random() * perguntas.length)];
      setPerguntaSelecionada({ pergunta });
    } else {
      return null;
    }
  };
  if (!allQuestions) return null;

  console.log("dataJsonKeys", allQuestions);

  return (
    <div style={main}>
      <SnackbarProvider>
        {categoriaSelecionada ? (
          <>
            <Pergunta {...perguntaSelecionada} />
            <Selecao handleGenerateQuestions={handleGenerateQuestions} />
          </>
        ) : null}
        <div style={containerPerguntas}>
          {allQuestions?.perguntas
            ? Object.keys(allQuestions?.perguntas).map((item, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => handleselectNewCategory(item)}
                    style={itemList}
                  >
                    <span
                      style={
                        categoriaSelecionada === item
                          ? tituloSelecionado
                          : titulo
                      }
                    >
                      {item}
                    </span>
                  </span>
                );
              })
            : null}
        </div>
      </SnackbarProvider>
    </div>
  );
};
const main = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100vh",
};
const containerPerguntas = {
  borderColor: "#b0b0b0",
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 5,
};
const itemList = {
  padding: 5,
  // marginVertical: 8,
  // marginHorizontal: 10,
  margin: 8,
  marginTop: 10,
  marginBottom: 10,
  display: "inline-block",
  cursor: "pointer",
};
const titulo = {
  color: "#b0b0b0",
  fontSize: 25,
  textTransform: "uppercase",
};
const tituloSelecionado = {
  color: "#ef4c4c",
  fontSize: 25,
  textTransform: "uppercase",
};

export default HomeScreen;
