import React from "react";
// import logo from './logo.svg';
// import './App.css';
import HomeScreen from "./screens/Home/Home";

function App() {
  return (
    <div className="App">
      <HomeScreen />
    </div>
  );
}

export default App;
