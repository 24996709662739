import React from "react";

import { useSnackbar } from "react-simple-snackbar";

const Pergunta = (pergunta) => {
  const [openSnackbar, closeSnackbar] = useSnackbar();

  if (Object.keys(pergunta).length === 0) return null;

  const handleCopyToClipboard = (text) => {
    console.log("text", text);

    navigator.clipboard
      .writeText(text)
      .then(() => {
        openSnackbar("Copiado.");
      })
      .catch((error) => {
        console.error(
          "Falha ao copiar texto para a área de transferência:",
          error
        );
      });
  };

  const buttonCopyToClipboard = (text) => {
    return (
      <a
        style={botaoCopiar}
        onClick={() => {
          handleCopyToClipboard(text);
        }}
      >
        <>Copiar para cliboard</>
      </a>
    );
  };

  // const renderItem = (item, index, extraData) => {
  //   return (
  //     <View>
  //       <Text style={index === extraData ? styles.respostaCorreta : styles.texto}>{item}</Text>
  //       <View style={styles.caixaComBotao}>{buttonCopyToClipboard(item)}</View>
  //     </View>
  //   );
  // };

  return (
    <div style={container}>
      {Object.keys(pergunta)?.map((item, index) => {
        const perguntaSelected = pergunta[item];
        return (
          <div style={items} key={index}>
            <div style={caixaComBotao}>
              <span style={titulo}>{perguntaSelected.PTBR}</span>
              {buttonCopyToClipboard(perguntaSelected.PTBR)}
            </div>
            <div style={caixaComBotao}>
              <span style={titulo}>{perguntaSelected.ENUS}</span>
              {buttonCopyToClipboard(perguntaSelected.ENUS)}
            </div>
            {perguntaSelected.respostas.map((item, index) => {
              return (
                <div key={index} style={boxPergunta}>
                  <span
                    style={
                      index === perguntaSelected.resposta
                        ? respostaCorreta
                        : texto
                    }
                  >
                    {item}
                  </span>
                  <span style={caixaComBotaoPergunta}>
                    {buttonCopyToClipboard(item)}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

// const styles = StyleSheet.create({
const container = {
  marginTop: 20,
};
const items = {
  alignItems: "flex-end",
};
const titulo = {
  fontSize: 15,
  fontWeight: "bold",
  padding: 5,
  borderWidth: 1,
  borderColor: "#b0b0b0",
  borderRadius: 5,
  marginVertical: 3,
  color: "#fff",
};
const texto = {
  fontSize: 15,
  padding: 5,
  borderWidth: 1,
  borderColor: "#b0b0b0",
  borderRadius: 5,
  marginVertical: 3,
  color: "#fff",
};
const respostaCorreta = {
  fontSize: 15,
  padding: 5,
  borderWidth: 1,
  borderColor: "#b0b0b0",
  borderRadius: 5,
  marginVertical: 3,
  color: "#fff",
  backgroundColor: "green",
};
const caixaComBotao = {
  // justifyContent: "space-between",
  // width: "100%",
  // alignItems: "flex-end",
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between",
};
const caixaComBotaoPergunta = {
  // justifyContent: "space-between",
  // width: "100%",
  // alignItems: "flex-end",
};
const botaoCopiar = {
  backgroundColor: "#b0b0b0",
  padding: 8,
  borderRadius: 5,
  display: "inline-block",
  // marginTop: 5,
  cursor: "pointer",
};
const boxPergunta = {
  display: "flex",
  justifyContent: "space-between",
  // alignItems: "center",
  marginBottom: 10,
  // backgroundColor: "#b0b0b0",
  // padding: 15,
  // borderRadius: 5,
  // display: "inline-block",
  // marginTop: 5,
  // cursor: "pointer",
};

export default Pergunta;
