import React from "react";

const Selecao = ({ handleGenerateQuestions }) => {
  return (
    <div style={container}>
      <button style={botaoGerarPergunta} onClick={handleGenerateQuestions}>
        Gerar Pergunta
      </button>
      {/* <TouchableOpacity style={styles.botaoTodasAsPerguntas}>
        <Text>Todas as perguntas</Text>
      </TouchableOpacity> */}
    </div>
  );
};

const container = {
  alignItems: "center",
  justifyContent: "center",
  // flex: 1,
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: "#b0b0b0",
  marginTop: 15,
  marginBottom: 5,
  width: "100%",
  textAlign: "center",
};
const botaoGerarPergunta = {
  backgroundColor: "#b0b0b0",
  padding: 20,
  marginTop: 5,
  // marginBottom: 5,
  borderRadius: 5,
  display: "inline-block",
  cursor: "pointer",
};
//   // botaoTodasAsPerguntas: {
//   //   backgroundColor: '#b0b0b0',
//   //   padding: 20,
//   //   marginVertical: 8,
//   //   borderRadius: 5,
//   // },

export default Selecao;
